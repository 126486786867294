<script>
import _ from 'lodash'
import CommonForm from '@/components/CommonForm.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import { getLocations } from '@/api/locations-api'
import { getUser } from '@/api/users-api'

import { mapActions, mapState } from 'vuex'

export default {
	name: 'RetrievePreviouslyGeneratedReport',
	components: { CommonForm, FormInput, FormSelect },
	data() {
		return {
			filter: {
				location: null,
				courierId: null,
				selectedPeriod: null,
				selectedReportType: ''
			},
			supportFlag: {
				disableLocationSelection: false
			},
			optionsData: {
				reportTypeOptions: [
					{ label: 'Generated', value: '' },
					{ label: 'Latest', value: 'latest' }
				],
				periodOptions: [],
				locationOptions: []
			}
		}
	},
	computed: {
		...mapState('reportGeneration', ['periodList']),
		isNoReportFound() {
			return (
				this.filter.location &&
				(!this.optionsData.periodOptions ||
					this.optionsData.periodOptions.length === 0)
			)
		}
	},
	async created() {
		this.optionsData.locationOptions = _.map(
			_.get(await getLocations(), 'data', []),
			location => ({
				label: location.id,
				value: _.pick(location, ['id', 'timezone'])
			})
		)
	},
	watch: {
		async ['filter.location'](newValue) {
			this.filter.selectedPeriod = null
			if (!newValue || !newValue.timezone) {
				return
			}

			this.optionsData.periodOptions = await this.getListCourierReport(
				newValue
			)
		},
		async ['filter.courierId'](newValue) {
			// Set the Location Selection disable flag
			this.supportFlag.disableLocationSelection = !_.isEmpty(newValue)

			const courier = _.isEmpty(newValue) ? null : await getUser(newValue) // Get the courier info
			// get location if existing in courier
			this.filter.location = _.get(
				_.find(this.optionsData.locationOptions, {
					label: _.get(courier, 'locationId', null)
				}),
				'value'
			)

			if (_.isNull(courier)) {
				return
			} else if (
				_.isEmpty(courier.roles) ||
				!courier.roles.includes('COR')
			) {
				// Not a courier user
				this.filter.courierId = null
				this.filter.location = null
				alert('This user is not a courier')
				return
			} else if (_.isEmpty(courier.locationId)) {
				// LocationId is missing
				if (
					window.confirm(
						`Courier: ${courier.info.name || ''} (ID: ${
							courier.id || ''
						}) is missing location info.\nConfirm to update courier`
					)
				) {
					// TODO: courier should not using the old user page for edit
					this.$router.push({
						path: `/users/edit/${courier.id}`
					})
				}
			}
		}
	},
	methods: {
		...mapActions('reportGeneration', [
			'getListCourierReport',
			'downloadSingleGeneratedReport',
			'downloadBulkGeneratedReports',
			'downloadLatestGeneratedReports',
			'pollingDownloadCourierReports'
		]),
		async submit() {
			if (!_.isEmpty(this.filter.courierId)) {
				//	For getting the single courier reports
				await this.downloadSingleGeneratedReport(this.filter)
			} else if (_.isEmpty(this.filter.selectedReportType)) {
				//	For getting the generated reports no the latest
				await this.downloadBulkGeneratedReports(this.filter)
			} else {
				//	For getting the latest reports
				const response = await this.downloadLatestGeneratedReports(
					this.filter
				)
				const uuid = _.get(response, 'data', null)
				if (uuid) {
					await this.pollingDownloadCourierReports(uuid)
				} else {
					alert('No report found')
				}
			}
		},
		reset() {
			this.filter = {
				location: null,
				courierId: null,
				selectedPeriod: null,
				selectedReportType: ''
			}
			this.supportFlag = {
				disableLocationSelection: false
			}
		}
	}
}
</script>
<template>
	<common-form submit-label="Download" :reset="reset" :submit="submit">
		<div class="form-title">
			<h3>Retrieve Previously Generated Report</h3>
			<h4>Download the latest version of generated report.</h4>
		</div>
		<form-select
			v-model="filter.location"
			label="Location"
			:rules="$validator.required('location')"
			:options="optionsData.locationOptions"
			:disable="supportFlag.disableLocationSelection"
		/>
		<form-input
			v-model="filter.courierId"
			label="Courier Id (optional)"
			style="width: 175px"
			:rules="$validator.nullableID('Courier Id')"
		/>
		<form-select
			v-model="filter.selectedPeriod"
			class="generated-report-period-list"
			:options="optionsData.periodOptions"
			:label="isNoReportFound ? 'No report found' : 'Period'"
			:rules="$validator.required('Period')"
			:disable="filter.location === null || isNoReportFound"
		/>
		<form-select
			v-model="filter.selectedReportType"
			:options="optionsData.reportTypeOptions"
			label="Report Type"
		/>
	</common-form>
</template>

<style scoped>
.generated-report-period-list {
	width: 200px;
}
.form-title {
	width: 100%;
}
</style>
