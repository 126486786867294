<script>
import Layout from '@/components/Layout.vue'
import ExportOpsUseReport from './components/ExportOpsUseReport.vue'
import ExportDriverUseReport from './components/ExportDriverUseReport.vue'
import RetrievePreviouslyGeneratedReport from './components/RetrievePreviouslyGeneratedReport.vue'
import GenerateCourierReport from './components/GenerateCourierReport.vue'
import { featureFlags } from '@/config'

import { mapState } from 'vuex'
export default {
	name: 'ReportGeneration',
	components: {
		Layout,
		ExportOpsUseReport,
		GenerateCourierReport,
		RetrievePreviouslyGeneratedReport,
		ExportDriverUseReport
	},
	computed: {
		//	Connect the state and getting polling action for trigger the loading even
		...mapState('reportGeneration', ['isPolling']),
		isDriverPaymentReportPhase2() {
			return featureFlags.LLP_24216_DRIVER_PAYMENT_REPORT_PHASE_2
		}
	}
}
</script>

<template>
	<layout class-name="report-generation" title="Report Generation">
		<export-ops-use-report />
		<generate-courier-report v-if="!isDriverPaymentReportPhase2" />
		<retrieve-previously-generated-report
			v-if="!isDriverPaymentReportPhase2"
		/>
		<export-driver-use-report v-if="isDriverPaymentReportPhase2" />
		<q-inner-loading :showing="isPolling" />
	</layout>
</template>
