<script>
import _ from 'lodash'
import moment from 'moment-timezone'
import CommonForm from '@/components/CommonForm.vue'
import DateInput from '@/components/DateInput.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import { getLocations } from '@/api/locations-api'
import { postDriverUseReport } from '@/api/driver-payment-report-api'
import { mapActions, mapState } from 'vuex'
import { getClients } from '@/api/clients-api'
import * as Sentry from '@sentry/vue'

const dateFormat = 'YYYY-MM-DD'

export default {
	name: 'ExportDriverUseReport',
	components: { CommonForm, FormInput, DateInput, FormSelect },
	data() {
		return {
			dateFormat,
			filter: {
				client: null,
				location: null,
				locale: null,
				userIds: '',
				startDate: moment().startOf('day').format(dateFormat),
				endDate: moment().endOf('day').format(dateFormat)
			},
			optionsData: {
				clientOptions: []
			},
			showConfirmPopup: false,
			minDate: moment()
				.subtract(6, 'months')
				.startOf('day')
				.format(dateFormat),
			maxDate: moment().endOf('day').format(dateFormat),
			clientList: ['FEDEX', 'QATEST']
		}
	},
	async created() {
		const locations = _.get(await getLocations(), 'data', [])

		const clients = (await getClients()) || []

		this.optionsData.clientOptions = clients
			.filter(client => this.clientList.includes(client.id))
			.map(client => ({
				label: client.id,
				value: {
					...client,
					locations: client.locations.map(locationId => ({
						label: locationId,
						value: locations.find(({ id }) => id === locationId)
					}))
				}
			}))
	},
	watch: {
		['filter.client']() {
			this.filter.location = null
			this.filter.locale = null
		},
		['filter.location']() {
			this.filter.locale = null
		}
	},
	computed: {
		...mapState('reportGeneration', ['isPolling']),
		isExportEnabled() {
			const { client, location, locale, startDate, endDate } = this.filter
			return (
				client &&
				location &&
				locale &&
				startDate &&
				endDate &&
				!this.isPolling
			)
		},
		isLocationSelectionEnabled() {
			const { client } = this.filter
			return client
		},
		isLocaleSelectionEnabled() {
			const { client, location } = this.filter
			return client && location
		},
		locationOptions() {
			return this.filter.client?.locations || []
		},
		localeOptions() {
			return this.filter.location?.locales || []
		},
		startDateMinDate() {
			const days44BeforeEndDate = moment(this.filter.endDate).add(
				-44,
				'days'
			)

			return moment(this.minDate) < days44BeforeEndDate
				? days44BeforeEndDate.format(dateFormat)
				: this.minDate
		},
		startDateMaxDate() {
			return moment(this.maxDate) > moment(this.filter.endDate)
				? this.filter.endDate
				: this.maxDate
		},
		endDateMinDate() {
			return moment(this.filter.startDate) > moment(this.minDate)
				? this.filter.startDate
				: this.minDate
		},
		endDateMaxDate() {
			const days44AfterStartDate = moment(this.filter.startDate).add(
				44,
				'days'
			)

			return moment(this.maxDate) > days44AfterStartDate
				? days44AfterStartDate.format(dateFormat)
				: this.maxDate
		},
		showReminder() {
			return !!this.filter.userIds
		}
	},
	methods: {
		...mapActions('reportGeneration', ['pollingDriverUseReports']),
		submit() {
			this.showConfirmPopup = true
		},
		async onConfirmSubmit() {
			const { client, location, locale, userIds, startDate, endDate } =
				this.filter
			this.reset()
			try {
				const report = await postDriverUseReport({
					clientId: client.id,
					locationId: location.id,
					locale,
					userIds: userIds || null,
					startDate,
					endDate
				})

				this.pollingDriverUseReports(report.id)
			} catch (error) {
				Sentry.captureMessage(
					'ExportDriverUseReport:Cannot create report',
					{
						level: 'error',
						extra: {
							error: {
								filter: {
									client,
									location,
									locale,
									userIds,
									startDate,
									endDate
								},
								stack: error.stack
							}
						}
					}
				)

				const errorMessage = _.get(
					error,
					'response.data.errors.0.detail',
					null
				)

				window.alert(errorMessage || `Cannot create report! ${error}`)
			}
		},
		reset() {
			this.filter = {
				client: null,
				location: null,
				locale: null,
				userIds: '',
				startDate: moment().startOf('day').format(dateFormat),
				endDate: moment().endOf('day').format(dateFormat)
			}
		}
	}
}
</script>
<template>
	<div>
		<common-form
			submit-label="Export"
			:reset="reset"
			:submit="submit"
			:disable-btn="!isExportEnabled"
		>
			<div class="form-title">
				<h3>Driver Compensation Report - Driver Use</h3>
				<p class="form-description">
					This report will generate calculated driver payment for the
					selected client jobs within the selected date range.
				</p>
				<p class="form-description">
					Please separate each courier ID with a ",".
				</p>
				<p class="form-description" v-show="showReminder">
					If the Courier didn't work on any delivery for the requested
					Client ID within the selected period, the report will not
					contain any information for this Courier ID.
				</p>
			</div>
			<form-select
				id="driver-use-report-client-id"
				v-model="filter.client"
				label="Client ID"
				:rules="$validator.required('client')"
				:options="optionsData.clientOptions"
			/>
			<form-select
				id="driver-use-report-location"
				v-model="filter.location"
				label="Location"
				:rules="$validator.required('location')"
				:options="locationOptions"
				:disable="!isLocationSelectionEnabled"
			/>
			<form-select
				id="driver-use-report-locale"
				v-model="filter.locale"
				label="Locale"
				:rules="$validator.required('locale')"
				:options="localeOptions"
				:disable="!isLocaleSelectionEnabled"
			/>
			<form-input
				id="driver-use-report-user-id"
				v-model="filter.userIds"
				label="Courier Id (optional)"
				:rules="$validator.nullableCommaSplitNumbers('Courier Id')"
			/>
			<date-input
				:key="`driverUseReportStartDate${startDateMaxDate}`"
				v-model="filter.startDate"
				label="Start Date"
				id="driverUseReportStartDate"
				jquery
				:picker-format="dateFormat"
				:min-date="startDateMinDate"
				:max-date="startDateMaxDate"
			/>
			<date-input
				:key="`driverUseReportEndDate${endDateMinDate}`"
				v-model="filter.endDate"
				label="End Date"
				id="driverUseReportEndDate"
				jquery
				:picker-format="dateFormat"
				:min-date="endDateMinDate"
				:max-date="endDateMaxDate"
			/>
		</common-form>
		<q-dialog v-model="showConfirmPopup" persistent>
			<q-card>
				<q-card-section class="row items-center">
					<span class="q-ml-sm">
						You are about to export multiple courier report, system
						might take between 5 and 30mins to process. Do you want
						to continue?
					</span>
				</q-card-section>

				<q-card-actions align="right">
					<q-btn
						no-caps
						flat
						label="Cancel"
						color="red"
						@click="reset"
						v-close-popup
					/>
					<q-btn
						no-caps
						label="Continue"
						color="primary"
						@click="onConfirmSubmit"
						v-close-popup
					/>
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<style lang="scss" scoped>
.form-title {
	width: 100%;
}

p.form-description {
	font-size: larger;
}
</style>
