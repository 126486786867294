<script>
import _ from 'lodash'
import moment from 'moment-timezone'
import CommonForm from '@/components/CommonForm.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import { getLocations } from '@/api/locations-api'
import { getUser } from '@/api/users-api'

import { mapActions } from 'vuex'

export default {
	name: 'GenerateCourierReport',
	components: { CommonForm, FormInput, FormSelect },
	data() {
		return {
			filter: {
				location: null,
				courierId: null,
				selectedPeriod: null
			},
			supportFlag: {
				disableLocationSelection: false
			},
			optionsData: {
				periodOptions: [],
				locationOptions: []
			}
		}
	},
	async created() {
		this.optionsData.locationOptions = _.map(
			_.get(await getLocations(), 'data', []),
			location => ({
				label: location.id,
				value: _.pick(location, ['id', 'timezone'])
			})
		)
	},
	watch: {
		['filter.location'](newValue) {
			this.filter.selectedPeriod = null
			if (!newValue || !newValue.timezone) {
				return
			}

			this.optionsData.periodOptions = this.generatePeriodList(
				newValue.timezone
			)
		},
		async ['filter.courierId'](newValue) {
			// Set the Location Selection disable flag
			this.supportFlag.disableLocationSelection = !_.isEmpty(newValue)

			const courier = _.isEmpty(newValue) ? null : await getUser(newValue) // Get the courier info
			// get location if existing in courier
			this.filter.location = _.get(
				_.find(this.optionsData.locationOptions, {
					label: _.get(courier, 'locationId', null)
				}),
				'value'
			)

			if (_.isNull(courier)) {
				return
			} else if (
				_.isEmpty(courier.roles) ||
				!courier.roles.includes('COR')
			) {
				// Not a courier user
				this.filter.courierId = null
				this.filter.location = null
				alert('This user is not a courier')
				return
			} else if (_.isEmpty(courier.locationId)) {
				// LocationId is missing
				if (
					window.confirm(
						`Courier: ${courier.info.name || ''} (ID: ${
							courier.id || ''
						}) is missing location info.\nConfirm to update courier`
					)
				) {
					// TODO: courier should not using the old user page for edit
					this.$router.push({
						path: `/users/edit/${courier.id}`
					})
				}
			}
		}
	},
	methods: {
		...mapActions('reportGeneration', [
			'generateCourierReports',
			'pollingDownloadCourierReports'
		]),
		async submit() {
			//	Ask the server to generate the report
			const { selectedPeriod, ...filter } = this.filter

			const selectedPeriodUTC = moment(selectedPeriod).utc().format()
			const uuid = await this.generateCourierReports({
				selectedPeriod: selectedPeriodUTC,
				...filter
			})

			if (uuid) {
				await this.pollingDownloadCourierReports(uuid)
			}
		},
		reset() {
			this.filter = {
				location: null,
				courierId: null,
				selectedPeriod: null
			}
			this.supportFlag = {
				disableLocationSelection: false
			}
			this.optionsData.periodOptions = []
		},
		generatePeriodList(timezone) {
			//	Build the option list for periodOptions for 6 month before
			//	the each month will separate as 'YYYY-MM (1H:1-15)' OR 'YYYY-MM  (2H:16-EoM)'
			const currDay = parseInt(moment.tz(timezone).format('D'))

			const result = []
			for (let i = 0; i < 6; ++i) {
				const tempDate = moment.tz(timezone).subtract(i, 'months')
				if (i !== 0) {
					result.push({
						label: `${tempDate.format('YYYY-MM')} (2H:16-EoM)`,
						value: `${tempDate
							.startOf('month')
							.add(15, 'days')
							.format('YYYY-MM-DD HH:mm Z')}`
					})
				}
				if (i !== 0 || currDay >= 16) {
					// i == 0: current month, current day >= 16: the first half month can be generated
					result.push({
						label: `${tempDate.format('YYYY-MM')} (1H:1-15)`,
						value: `${tempDate
							.startOf('month')
							.format('YYYY-MM-DD HH:mm Z')}`
					})
				}
			}
			return result
		}
	}
}
</script>
<template>
	<common-form submit-label="Generate" :reset="reset" :submit="submit">
		<div class="form-title">
			<h3>Generate Courier Report</h3>
			<h4>
				Report will be updated in Courier Management page once it's
				completed.
			</h4>
		</div>
		<form-select
			v-model="filter.location"
			label="Location"
			:options="optionsData.locationOptions"
			:rules="$validator.required('Location')"
			:disable="supportFlag.disableLocationSelection"
		/>
		<form-input
			v-model="filter.courierId"
			label="Courier Id (optional)"
			style="width: 175px"
			:rules="$validator.nullableID('Courier Id')"
		/>
		<form-select
			v-model="filter.selectedPeriod"
			class="courier-report-period-options"
			:options="optionsData.periodOptions"
			label="Period"
			:rules="$validator.required('Period')"
			:disable="filter.location === null"
		/>
	</common-form>
</template>

<style scoped>
.courier-report-period-options {
	width: 200px;
}
.form-title {
	width: 100%;
}
</style>
