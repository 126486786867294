<script>
import _ from 'lodash'
import moment from 'moment-timezone'
import CommonForm from '@/components/CommonForm.vue'
import DateInput from '@/components/DateInput.vue'
import FormInput from '@/components/FormInput.vue'
import { getLocation } from '@/api/locations-api'
import { getUser } from '@/api/users-api'
import { featureFlags } from '@/config'
import { mapActions } from 'vuex'

const dateFormat = 'YYYY-MM-DD'

export default {
	name: 'ExportOpsUseReport',
	components: { CommonForm, FormInput, DateInput },
	data() {
		return {
			dateFormat,
			filter: {
				locationId: null,
				courierId: null,
				startDate: moment().startOf('day').format(dateFormat),
				endDate: moment().endOf('day').format(dateFormat)
			},
			minDate: moment()
				.subtract(6, 'months')
				.startOf('day')
				.format(dateFormat),
			maxDate: moment().endOf('day').format(dateFormat)
		}
	},
	watch: {
		async ['filter.courierId'](newValue) {
			const courier = _.isEmpty(newValue) ? null : await getUser(newValue) // Get the courier info
			this.filter.locationId = _.get(courier, 'locationId', null) // get locationId if existing in courier

			if (_.isNull(courier)) {
				return
			} else if (
				_.isEmpty(courier.roles) ||
				!courier.roles.includes('COR')
			) {
				// Not a courier user
				this.filter.courierId = null
				this.filter.locationId = null
				alert('This user is not a courier')
				return
			} else if (_.isEmpty(courier.locationId)) {
				// LocationId is missing
				if (
					window.confirm(
						`Courier: ${courier.info.name || ''} (ID: ${
							courier.id || ''
						}) is missing location info.\nConfirm to update courier`
					)
				) {
					// TODO: courier should not using the old user page for edit
					this.$router.push({
						path: `/users/edit/${courier.id}`
					})
				}
			}
		}
	},
	computed: {
		isDriverPaymentReportPhase2() {
			return featureFlags.LLP_24216_DRIVER_PAYMENT_REPORT_PHASE_2
		},
		startDateMinDate() {
			const days44BeforeEndDate = moment(this.filter.endDate).add(
				-44,
				'days'
			)

			return moment(this.minDate) < days44BeforeEndDate
				? days44BeforeEndDate.format(dateFormat)
				: this.minDate
		},
		startDateMaxDate() {
			return moment(this.maxDate) > moment(this.filter.endDate)
				? this.filter.endDate
				: this.maxDate
		},
		endDateMinDate() {
			return moment(this.filter.startDate) > moment(this.minDate)
				? this.filter.startDate
				: this.minDate
		},
		endDateMaxDate() {
			const days44AfterStartDate = moment(this.filter.startDate).add(
				44,
				'days'
			)

			return moment(this.maxDate) > days44AfterStartDate
				? days44AfterStartDate.format(dateFormat)
				: this.maxDate
		},
		disableBtn() {
			return !this.filter.locationId || !this.filter.courierId
		}
	},
	methods: {
		...mapActions('reportGeneration', ['exportOpsUseReportByCourierId']),
		async submit() {
			//	TODO: the rules checking for courierId input is not auto detected when created.
			//	This checking is for avoiding the detection missing issue
			if (this.filter.courierId && this.filter.locationId) {
				const timezone = _.get(
					await getLocation(this.filter.locationId),
					'timezone'
				)
				const modifiedFilter = {
					startDate: moment
						.tz(this.filter.startDate, timezone)
						.startOf('day')
						.utc()
						.format(),
					endDate: moment
						.tz(this.filter.endDate, timezone)
						.endOf('day')
						.utc()
						.format()
				}

				await this.exportOpsUseReportByCourierId({
					locationTimezone: timezone,
					...this.filter,
					...modifiedFilter
				})
			} //	Ask server to export the courier reports
			else {
				this.filter.courierId = ''
			}
		},
		reset() {
			this.filter = {
				locationId: null,
				courierId: null,
				startDate: moment().startOf('day').format(dateFormat),
				endDate: moment().endOf('day').format(dateFormat)
			}
		}
	}
}
</script>
<template>
	<common-form
		submit-label="Export"
		:reset="reset"
		:submit="submit"
		:disable-btn="disableBtn"
	>
		<div class="form-title">
			<h3>
				{{
					isDriverPaymentReportPhase2
						? 'Driver Assignment Report - Ops Use'
						: 'Export Courier Report (One-off)'
				}}
			</h3>
			<h4>
				{{
					isDriverPaymentReportPhase2
						? 'This report will display all the jobs of the requested courier ID within the selected date range.'
						: 'Report will be generated and exported at real-time. It will NOT be in the system.'
				}}
			</h4>
		</div>
		<form-input
			v-model="filter.courierId"
			label="Courier Id"
			:rules="$validator.id('Courier Id')"
		/>
		<date-input
			:key="`opsUseReportStartDate${startDateMaxDate}`"
			v-model="filter.startDate"
			label="Start Date"
			jquery
			:picker-format="dateFormat"
			:min-date="startDateMinDate"
			:max-date="startDateMaxDate"
		/>
		<date-input
			:key="`opsUseReportEndDate${endDateMinDate}`"
			v-model="filter.endDate"
			label="End Date"
			:picker-format="dateFormat"
			jquery
			:min-date="endDateMinDate"
			:max-date="endDateMaxDate"
		/>
	</common-form>
</template>

<style lang="scss" scoped>
.form-title {
	width: 100%;
}
</style>
